import React from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'
import './footer.scss'

const Footer = () => (
    <>
        <Fade>
            <footer className="main-footer">
                <div className="container">
                    <h4>Entre em contato por e-mail ou telefone para solicitações de orçamento,
                        especificações de produtos e montagens.</h4>
                    
                    <p className="main-footer__mail">
                        <a href="mailto:contato@egbsystems.com.br">comercial@egbsystems.com.br</a></p>
                    <p className="main-footer__phone">(11) 2108-5591</p>

                    <div className="main-footer__columns">
                        <p>© EGB Systems 2023</p>
                        <p><Link to="/politica-de-privacidade">Política de Privacidade da EGB Systems</Link></p>
                        <p>Webdesign by <a href="http://yupper.com.br" target="_blank" rel="noreferrer">Yupper Digital</a></p>
                    </div>
                </div>
            </footer>
        </Fade>
    </>
)

export default Footer
