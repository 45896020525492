import React, { useState } from 'react'
import { Link } from 'gatsby'

import './header.scss'
import './main-nav.scss'

const Header = () => {
    const [menu, setMenu] = useState('')
    

    const toggleMenu = () => {
        if (menu === '') {
            setMenu('is-active')
        } else {
            setMenu('is-closing')
            setTimeout(() => {
                setMenu('')
            }, 250)
        }
    }

    return(
        <>
            <div className="main-header">
                <div className="main-header__container">
                    <h1 className={`${menu}`}><Link to="/">Engebras</Link></h1>

                    <button className={ `open-menu ${menu}`} onClick={ toggleMenu }>
                        Menu</button>
                </div>
            </div>
            <nav className={`main-nav ${menu}`}>
                <div className="main-nav__container">
                    <ul>
                        <li><Link to="/solucoes">Soluções</Link></li>
                        <li><Link to="/empresa">A Empresa</Link></li>
                        <li><Link to="/sustentabilidade">Sustentabilidade</Link></li>
                        <li><Link to="/contato">Contato</Link></li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Header
