/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'

import Header from '../Header'
import Footer from '../Footer'
import BannerCookie from '../BannerCookie';

import './layout.scss'

const Layout = ({ children }) => (
  <>
    <Header />
    <main>{children}</main>
    <BannerCookie />
    <Footer />
  </>
)

export default Layout
