import React, { useState, useEffect} from 'react'
import { Link } from 'gatsby'

import './bannerCookie.scss'

const BannerCookie = () => {
    const [hasCookie, setCookieState] = useState(null);

    useEffect(() => {
        if (document.cookie.includes('site-engebras')) {
            setCookieState(true);
        }
    }, []);

    useEffect(() => {
        if (hasCookie) {
            const now = new Date();
            now.setTime(now.getTime() + 180 * 60 * 60 * 24 * 1000);
            document.cookie = `site-engebras=true; expires=${now.toUTCString()}; path=/`;
        }
    }, [hasCookie])

    const acceptCookie = () => setCookieState(true);

    return (
        <>
            { !hasCookie &&
                <div className="banner-cookie">
                    <p>Ao navegar neste site, você concorda com a nossa <Link to="/politica-de-privacidade">política de privacidade</Link></p>
                    <p><button className="banner-cookie__button" onClick={ acceptCookie }>Prosseguir</button></p>
                </div>
            }
        </>
    )
}

export default BannerCookie;